import React from 'react';
import Layout from '../layouts/en';
import OtherInvestments from '../components/OtherInvestments';
import { Link } from 'gatsby';
import iconTransaction from '../assets/images/icons/icon-transaction.svg';
import iconSkills from '../assets/images/icons/icon-skills.svg';
import iconStatistics from '../assets/images/icons/icon-statistics.svg';
import Scroll from './../components/Scroll';
import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";

export default class InvestmentFocusPage extends React.Component {

    client = new ApolloClient({
        uri: `${process.env.GATSBY_API_URL}/graphql`,
    });

    constructor(props) {
        super(props);
        this.state = {
            related_portfolio: null
        }
    }

    componentDidMount() {
        this.getRelatedPortfolio();
    }

    getRelatedPortfolio() {
        this.client.query({
            fetchPolicy: 'no-cache',
            query: gql`
                {
                    portfolioens(
                        where: {
                            active: true
                        }
                    ) {
                        slug
                        company_name
                        aquired
                        company_logo {
                            url
                        }
                        portfolio_image {
                            url
                        }
                        Sold
                        category
                    }
                }
            `
        }).then(
            (response) => {
                if (response.data.portfolioens.length > 0) {
                    const randomPortfolioIndex = Math.floor(Math.random() * response.data.portfolioens.length)
                    this.setState({related_portfolio: response.data.portfolioens[randomPortfolioIndex]});
                } else {
                    this.setState({related_portfolio: null});
                }
            }
        )
    }

  render() {
    return (
        <Layout>
        <header className="masthead invested-criteria-wrapper" id="header">
            <div className="center-block text-center col-sm-12">
                <h1 className="homepage-main-text">
                    Criteria we look for in potential investments
                </h1>
            </div>
        </header>
        <section className="container investment-criteria-container content-wrapper">
            <div className="col-sm-12">
                <h1 className="dark-left-heading">Investment focus</h1>
                <span className="horizontal-line gray-background"></span>

                <div className="row">
                <div className="col-lg-4 float-left investment-focus-section-wrapper">
                        <img src={iconSkills} alt="" className="no-top-margin investment-icon"/>
                        <h4 className="fs-1_5rem">Markets & Company<span className="mobile-hidden"><br/>&nbsp;</span></h4>
                        <table className="invesment-focus-table">
                            <tbody>
                                <tr><td>Active in production, (technical) wholesale or business services</td></tr>
                                <tr><td>Revenues between € 10M and € 100M</td></tr>
                                <tr><td>Headquartered in the Netherlands or Germany</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-4 float-left investment-focus-section-wrapper">
                        <img src={iconStatistics} alt="Market criteria" className="no-top-margin investment-icon custom-investment-icon"/>
                        <h4 className="fs-1_5rem">Transaction<span className="mobile-hidden"><br/>&nbsp;</span></h4>
                        <table className="invesment-focus-table">
                            <tbody>
                                <tr><td>Buy-outs, growth, succession planning , challenging financial situation, transformation, carve-outs</td></tr>
                                <tr><td>Equity ticket up to € 12M</td></tr>
                                <tr><td>Controlling stake, flexible in deal and capital structure</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-4 float-left investment-focus-section-wrapper">
                        <img src={iconTransaction} alt="Transactional criteria" className="no-top-margin investment-icon custom-investment-icon"/>
                        <h4 className="fs-1_5rem">Added value<span className="mobile-hidden"><br/>&nbsp;</span></h4>
                        <table className="invesment-focus-table">
                            <tbody>
                                <tr><td>Swift decision making during and after transaction</td></tr>
                                <tr><td>Team is mix of entrepreneurs & operational and financial professionals</td></tr>
                                <tr><td>Involvement on strategy, prioritization, driving & facilitating transformations</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 float-left investment-focus-section-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="gray-rectangle">
                                    <p className="black-color">Does your company meet these requirements and are you prepared to get get a potential investment?</p>
                                    <br/>
                                    <Link to="/?scroll-to=contact">
                                        <span className="blue-color"><strong>Contact us for an appointment.</strong></span>
                                    </Link>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="clearfix"></div>

            <div className="narrow-wrapper col-sm-12 investment-focus-section-wrapper other-investments">
                <OtherInvestments data={this.state.related_portfolio} locale={'en-GB'}/>
            </div>
        </section>
    
        <br/>
        <Scroll type="id" element="header">
                <div className="investment-focus-back back-to-top-wrapper">
                <a href="?scroll-to=header">
                    <i className="fas fa-angle-double-up"></i>
                    <br />
                    <span className="back-to-top">Back to top</span>
                </a>
            </div>
        </Scroll>
      </Layout>
    )

  }

}

